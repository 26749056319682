<template>
  <div
      v-if="needUpdate"
      class="update-box"
  >
    <a href="/">需要更新</a>
  </div>
  <router-view/>
  <div class="beian-container">
    <a class="beian" href="https://beian.miit.gov.cn">粤ICP备2022114794号</a>
  </div>
</template>

<script setup>
import { useCheckUpdate } from '@/composables/useCheckUpdate'

const {needUpdate} = useCheckUpdate()
</script>

<style lang="scss">
@import "~@/assets/reset.scss";

.update-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.beian-container {
  height: 100px;

  .beian {
    position: fixed;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
  }
}
</style>
